export const eventCreationConfig = {
  method: "POST",
  url: "/event/",
  body: {
    "theme_obj": {
      "logos": [
          "https://theme.zdassets.com/theme_assets/11148313/f714b82beff6bdba8e2104abda03e520e7cf53d0.png"
      ],
      "font_type": "Custom",
      "auth_theme": true,
      "show_borders": false,
      "sidebar_blur": "200px",
      "sidebar_alpha": 0.05,
      "disallow_login": false,
      "theme_font_src": "https://d6d4ismr40iw.cloudfront.net/event/c975ef08-b54b-4be5-ba41-66db49a4bbe9/fonts/Proxima_Nova_Bold.otf",
      "page-item-alpha": 0.035,
      "page_item_alpha": "0.3",
      "theme_font_name": "",
      "event_background": "Url('https://cdn.filestackcontent.com/wpC7d9xOTle5NdRyy1pF')",
      "name_card_radius": "12px",
      "splash_font_type": "Custom",
      "splash_page_hero": "Url('https://cdn.filestackcontent.com/wpC7d9xOTle5NdRyy1pF')",
      "stage_background": "rgba(0, 9, 63, 1)",
      "stage_text_color": "var(--primary-text-color)",
      "accent_text_color": "rgba(255, 255, 255, 1)",
      "splash_text_color": "rgba(255, 255, 255, 1)",
      "navbar_shade_alpha": "0.5",
      "primary_text_color": "rgba(255, 255, 255, 1)",
      "theme_accent_color": "rgba(200, 255, 0, 1)",
      "virtual_background": {
          "url": "https://cdn.filestackcontent.com/wpC7d9xOTle5NdRyy1pF"
      },
      "nav_bar_shade_alpha": 0,
      "theme_primary_color": "rgba(0, 9, 63, 1)",
      "ctrl_bar_shade_alpha": "0.3",
      "name_card_background": "var(--theme-primary-color)",
      "name_card_text_color": "var(--primary-text-color)",
      "secondary_text_color": "rgba(17, 17, 17, 1)",
      "page_item_hover_alpha": "0.6",
      "splash_theme_font_src": "https://d6d4ismr40iw.cloudfront.net/event/c975ef08-b54b-4be5-ba41-66db49a4bbe9/fonts/Proxima_Nova_Bold.otf",
      "theme_secondary_color": "rgba(200, 255, 0, 1)",
      "lower_third_background": "var(--theme-secondary-color)",
      "lower_third_text_color": "var(--secondary-text-color)",
      "splash_theme_font_name": "",
      "stage_background_shade": "#0085de",
      "virtual_background_url": "https://cdn.filestackcontent.com/wpC7d9xOTle5NdRyy1pF",
      "mobile_event_background": "Url('https://cdn.filestackcontent.com/NdT2jfWtTnmHxcB5y193')",
      "mobile_splash_page_hero": "Url('https://cdn.filestackcontent.com/NdT2jfWtTnmHxcB5y193')",
      "mobile_stage_background": "rgba(0, 9, 63, 1)",
      "name_card_title_display": "visible",
      "screen_saver_background": "rgba(0, 3, 53, 1)",
      "screen_saver_text_color": "#fff",
      "splash_background_color": "rgba(0, 9, 63, 1)",
      "splash_background_shade": "255, 255, 255",
      "primary_background_shade": "255, 255, 255",
      "splash_text_onimage_color": "rgba(255, 255, 255, 1)",
      "alt_primary_background_shade": "0, 0, 0"
    },
    title: "",
    location: "",
    is_hybrid: false,
    is_available_on_demand: false,
    description: "",
    start_time: "2022-11-09T20:00:00+05:30",
    end_time: "2022-11-09T20:15:00+05:30",
    timezone: "Asia/Kolkata (GMT+05:30)",
    have_broadcasts: true,
    have_discussions: true,
    organization: "e5b93fb3-a5a6-451a-99da-016dbb2c1a33",
    owner: "1346f6fd-6c2a-4c59-9500-534263f33e77",
    magic_link_enabled: true,
    tab_descriptions: {
      rooms:"Join a round table to discuss moderated topics with your fellow attendees",
      primary_booth: "Join a booth to know more about a sponsor",
      secondary_booth: "Join a booth to know more about a sponsor",
      people: "Connect with your fellow attendees",
      breakouts: "Join one of the break out rooms to discuss on an agenda"
    },
    is_test: true,
    default_language: "en"
  }
};

export const trackCreationConfig = {
  url: "/event/tracks/",
  body: {"event":"b3f9dac0-6bb9-4f12-90d7-aba5a50305f9","custom":true,"title":"Track 2","agenda_items":"","sort_priority":1}
};

export const broadcastCreationConfig = {
  url: "/event/broadcasts/",
  body: {
    title: "",
    description: "",
    location: "",
    have_text_qna: true,
    have_video_qna: true,
    hide_watcher_count: true,
    have_polls: false,
    start_time: "",
    end_time: "",
    event: "",
    broadcast_type: "",
    youtube_live_url: "",
    facebook_live_url: "",
    youtube_stream_key: "",
    facebook_stream_key: "",
    custom_stream_url: "",
    custom_stream_key: "",
    slide_assets: [],
    video_assets: [],
    speakers: [],
    tracks: [],
    resources: [],
    medialive_rtmp_input: null
  }
}

export const rtmpInputCreationConfig = {
  url: "event/rtmp_inputs/",
  body: {
    "name": "ABC",
    "event": "b3f9dac0-6bb9-4f12-90d7-aba5a50305f9"
  }
}