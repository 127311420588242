import backend from "./apiClient/backend.ts";
import { broadcastCreationConfig, eventCreationConfig, rtmpInputCreationConfig, trackCreationConfig } from "./apiConfigs";
import { addEventSpeaker, getEventFromUberEndpoint, registerUser } from "./event.service";
import { timeFormatter } from "./time.service";

let verbs, nouns, adjectives, adverbs, preposition;
nouns = ["bird", "clock", "plastic", "duck", "professor", "hamster", "dog", "football", "webcam", "microphone"];
verbs = ["kicked", "ran", "flew", "dodged", "sliced", "rolled", "died", "breathed", "slept", "killed"];
adjectives = ["beautiful", "lazy", "professional", "lovely", "dumb", "rough", "soft", "hot", "vibrating", "slimy"];
adverbs = ["slowly", "elegantly", "precisely", "quickly", "sadly", "humbly", "proudly", "shockingly", "calmly", "passionately"];
preposition = ["down", "into", "up", "on", "upon", "below", "above", "through", "across", "towards"];

function getRandomPhrase(long = false) {
  function getSentence() {
    var rand1 = Math.floor(Math.random() * 10);
    var rand2 = Math.floor(Math.random() * 10);
    var rand3 = Math.floor(Math.random() * 10);
    var rand4 = Math.floor(Math.random() * 10);
    var rand5 = Math.floor(Math.random() * 10);
    var rand6 = Math.floor(Math.random() * 10);

    if (long) {
      return "The " + adjectives[rand1] + " " + nouns[rand2] + " " + adverbs[rand3] + " " + verbs[rand4] + " because some " + nouns[rand1] + " " + adverbs[rand1] + " " + verbs[rand1] + " " + preposition[rand1] + " a " + adjectives[rand2] + " " + nouns[rand5] + " which, became a " + adjectives[rand3] + ", " + adjectives[rand4] + " " + nouns[rand6] + ".";
    }
    
    return adjectives[rand1] + " " + nouns[rand2] + " " + adverbs[rand3] + " " + verbs[rand4];
  };
  const sentence = getSentence();

  return sentence[0].toUpperCase() + sentence.slice(1);
}

async function createEvent(start, end) {
  const { url } = eventCreationConfig;
  const body = { ...eventCreationConfig.body };
  body.start_time = new Date(start).toISOString();
  body.end_time = new Date(end).toISOString();
  body.title = getRandomPhrase();
  body.description = getRandomPhrase(true);
  body.organization = window.user.organization;
  body.owner = window.user.id;

  const response = await backend.post(url, body);
  return response.data;
}


async function createTrack(eventId) {
  const { url } = trackCreationConfig;
  const body = { ...eventCreationConfig.body };
  body.title = getRandomPhrase();
  body.event = eventId;

  const response = await backend.post(url, body);
  console.log(response);
  return response.data;
}

// async function createRTMPInput(event) {
//   const { url } = rtmpInputCreationConfig;
//   const body = { ...rtmpInputCreationConfig.body };
//   body.event = event;
//   body.name = getRandomPhrase();

//   const response = await backend.post(url, body);
//   return response.data;
// }

async function createBroadcast(event, tracks, start, end, type, speakerIds =[]) {
  const backendNameConfig = {
    LIVE: "LIVE_HYBRID",
    PRERECORDED: "SIMULIVE",
    // RTMP: "LIVE_EXTERNAL"
  }
  const { url } = broadcastCreationConfig;
  const body = { ...broadcastCreationConfig.body };
  body.start_time = new Date(start).toISOString();
  body.end_time = new Date(end).toISOString();
  body.title = getRandomPhrase();
  body.description = getRandomPhrase(true);
  body.event = event;
  body.tracks = tracks;
  body.broadcast_type = backendNameConfig[type];
  body.speakers = speakerIds;

  // if (type === "RTMP") {
  //   body.medialive_rtmp_input = (await createRTMPInput(event)).id;
  // }

  const response = await backend.post(url, body);
  return response.data;
}

export async function createFullFledgedEvent(start, end, sessionTypes) {
  const event = await createEvent(start, end);
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();

  const uberEvent = await getEventFromUberEndpoint(event.id);

  const track1 = uberEvent.tracks[0];
  const track2 = await createTrack(event.id);
  
  const mandatorySessions = [...sessionTypes];

  const trackConfig = {
    track1: {
      ...track1,
      items: [],
      endTime: startTime
    },
    track2: {
      ...track2,
      items: [],
      endTime: startTime
    }
  }

  const registerUsers = () => {
    const suffixes = ["tester", "mister", "sister", "blister", "minister"];
    suffixes.forEach((s) => {
      const currEmail = window.user.email;
      const afterAt = currEmail.split("@")[1];
      const beforeAt = currEmail.split("@")[0];
      const userName = beforeAt.split("+")[0];
      const testMailId = `${userName}+${s}@${afterAt}`;
      const firstName = adjectives[Math.floor(Math.random() * adjectives.length)];
      const lastName = nouns[Math.floor(Math.random() * nouns.length)];
      registerUser(testMailId, event.id, firstName, lastName);
    });
  }

  registerUsers();

  const addEventSpeakers = async () => {
    const suffixes = ["tester", "mister", "sister", "blister", "minister"];
    const speakers = await Promise.allSettled(suffixes.map(async (s) => {
      const currEmail = window.user.email;
      const afterAt = currEmail.split("@")[1];
      const beforeAt = currEmail.split("@")[0];
      const userName = beforeAt.split("+")[0];
      const testMailId = `${userName}+${s}Speaker@${afterAt}`;
      const firstName = adjectives[Math.floor(Math.random() * adjectives.length)];
      const lastName = nouns[Math.floor(Math.random() * nouns.length)];
      const response = await addEventSpeaker(testMailId, event.id, firstName, lastName);
      return response.user.id;
    }));
    return speakers.map(s => s.value);
  }

  const speakerIds = await addEventSpeakers();
  console.log(speakerIds)

  function isComplete() {
    return Object.keys(trackConfig).reduce((acc, cur) => acc && (trackConfig[cur].endTime + 5 * 60 * 1000 > endTime), true)
  }

  while (!isComplete()) {

    for ( let key in trackConfig ) {
      const config = trackConfig[key];

      if (config.endTime + 5 * 60 * 1000 > endTime) {
        continue;
      }

      let sessionType = sessionTypes[Math.floor(Math.random() * sessionTypes.length)];

      if (mandatorySessions.length) {
        sessionType = mandatorySessions.pop();
      }

      let duration = 5 * 60 * 1000;

      if (!mandatorySessions.length) {
        if (config.endTime + 10 * 60 * 1000 < endTime) {
          if (Math.random() > 0.5) {
            duration = 10 * 60 * 1000;
          }
        }
      }

      config.items.push({
        sessionType,
        start: config.endTime,
        startNiceTime: timeFormatter.format(config.endTime),
        end: config.endTime + duration,
        endNiceTime: timeFormatter.format(config.endTime + duration)
      });

      config.endTime = config.endTime + duration;
      config.endNiceTime = timeFormatter.format(config.endTime + duration);
    }
  }

  Promise.allSettled(Object.keys(trackConfig).map(t => {
    const track = trackConfig[t];
    return Promise.allSettled(track.items.map(async (item) => {
      const session = await createBroadcast(event.id, [track.id], item.start, item.end, item.sessionType, speakerIds);
      track.agenda_items = (track.agenda_items || []).push(session.id);
    }));
  }));
  
  return event;
}