import React, { useEffect, useState } from "react";
import { Loader } from "./Loader";

export default function JokeLoader() {
  const [joke, setJoke] = useState({
    setup: "Why did the chicken cross the road?",
    punchline: "To get to the other side!",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      fetch("https://official-joke-api.appspot.com/random_joke")
        .then((res) => res.json())
        .then((data) => {
          setJoke(data);
        });
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="bg-slate-800 border-2 border-solid border-slate-700 max-w-2xl w-fit h-108 rounded-md p-16 flex justify-center items-center text-white flex-col gap-8 text-center">
      <Loader />
      <div className="text-white text-2xl mb-4">
        Backend is doing your work. Till it completes read some jokes
      </div>
      <div className="text-green-500 text-2xl mt-6 mb-4">
        Here is a Joke For you:
      </div>
      <div className="text-white text-xl mt-6 mb-1">{joke.setup}</div>
      <div className="text-white text-xl mb-6">{joke.punchline}</div>
    </div>
  );
}
