import { getEnv } from "../constants";

export async function setUpLoadTest(eventId) {
  try {
    const resp = await fetch(`${getEnv("LOAD_TESTER_URL")}/automation/setup`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        eventId,
      }),
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData.message;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}

export async function getSetupStatus() {
  try {
    const resp = await fetch(`${getEnv("LOAD_TESTER_URL")}/automation/status`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}

export async function chatLoadTest(channelId, noOfMessages, noOfSecs, action) {
  try {
    let url = `${getEnv("LOAD_TESTER_URL")}/chat/load-test`;
    if (action === "add_reactions") {
      url = `${getEnv("LOAD_TESTER_URL")}/chat/add-reactions-load-test`;
    }
    const resp = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channelId,
        noOfMessages,
        noOfSecs,
      }),
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}

export async function qnaLoadTest(channelId, noOfMessages, noOfSecs, action) {
  try {
    let url = `${getEnv("LOAD_TESTER_URL")}/qna/load-test`;
   
    const resp = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channelId,
        noOfMessages,
        noOfSecs,
      }),
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}

export async function addSpeakers(eventId, noOfSpeakers) {
  try {
    let url = `${getEnv("LOAD_TESTER_URL")}/add-speakers`;
   
    const resp = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        noOfSpeakers,
        eventId
      }),
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}


export async function publicChatLoadTest(mmid, noOfMessages, noOfSecs, action) {
  try {
    let url = `${getEnv("LOAD_TESTER_URL")}/chat/public_load`;
    if (action === "add_reactions") {
      url = `${getEnv("LOAD_TESTER_URL")}/chat/add-reactions-load-test`;
    }
    const resp = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mmid,
        noOfMessages,
        noOfSecs,
      }),
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}

export async function joinChannelToUsers(channelId) {
  try {
    const resp = await fetch(`${getEnv("LOAD_TESTER_URL")}/chat/join_channel`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channelId,
      }),
    });
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error setting up load test", error);
    throw error;
  }
}

export async function cleanUpLoadTest() {
  try {
    const resp = await fetch(
      `${getEnv("LOAD_TESTER_URL")}/automation/cleanup`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: "abc",
        }),
      }
    );
    const jsonData = await resp.json();
    if (!resp.ok) {
      throw Error(jsonData.message);
    }
    return jsonData;
  } catch (error) {
    console.error("Error cleaning up load test", error);
    throw error;
  }
}
