import {  useState, memo } from "react";
import { useParams } from "react-router-dom";
import { addSpeakers } from "../services/automationTest.service";
import JokeLoader from "../atoms/JokeLoader";

const AddSpeakers = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [noOfSpeakers, setNoOfSpeakers] = useState(0);
  const [error, setError] = useState(false);

  const create = async () => {
    if (noOfSpeakers === 0) {
      setError("No of speakers required !!");
      return;
    }

    try {
      setLoading(true);
      const data = await addSpeakers(eventId, noOfSpeakers);
      if (data?.code === 400) {
        setLoading(false);
        setError(data?.message);
        return;
      }
      setTimeout(() => {
        setLoading(false);
      }, noOfSpeakers * 2000);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  if (loading) {
    return <JokeLoader />;
  }

  return (
    <div className="bg-slate-800  top-40 max-w-2xl w-full rounded-md p-16">
      <div className="text-3xl text-slate-300 font-bold">Add Speakers</div>
      <div className="text-md text-slate-300 font-light mb-8">
        Let's add some speakers to the event
      </div>
      <div className="text-md text-red-500 font-light mb-8">{error}</div>

      <div className="flex flex-col text-white justify-between my-4">
        <div>
          <label
            htmlFor="token"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of speakers you want to add:
          </label>
          <input
            type="number"
            id="token"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="5"
            required
            value={noOfSpeakers}
            onChange={(e) => {
              setNoOfSpeakers(e.target.value);
              setError("");
            }}
          />
        </div>
      </div>
      <button
        onClick={create}
        className="bg-blue-700 text-white w-full rounded-md box-border border-solid border-2 border-blue-600 p-4 hover:bg-blue-600 mt-12"
      >
        Let's Start
      </button>
    </div>
  );
};

export default memo(AddSpeakers);
