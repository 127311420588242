import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import { Loader } from "../atoms/Loader";
import { getUserEvents } from "../services/event.service";
import { startToEndDateString } from "../services/time.service";

const EventList = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const eventsData = await getUserEvents(date);
      setEvents(eventsData.data.results);
      setLoading(false);
    }
    init();
  }, [date]);

  const changeDate = (diff = 0) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + diff);
    setDate(newDate);
  }
  
  return (
    <div className="bg-slate-800 basis-80 m-4 br-4 rounded-md p-2 overflow-scroll text-white flex flex-col">
      <div className="flex justify-between p-2 px-8 rounded-md bg-blue-900 sticky top-0">
        <button onClick={() => changeDate(-1)} className="text-blue-300 text-xs font-bold items-center">prev</button>
          { date.toDateString() }
        <button onClick={() => changeDate(+1)} className="text-blue-300 text-xs font-bold items-center">next</button>
      </div>
      {
          loading
            ? <div className="grow flex justify-center items-center">
              <Loader />
            </div>
      : <div className="flex flex-col items-stretch justify-start">
          {
            events.map(event => (
              <Link key={event.id}  to={`/e/${event && event.id}`}>
                <button className="hover:bg-slate-900 p-4 flex flex-col items-start justify-start w-full overflow-hidden">
                  <div className="text-slate-200 text-xl truncate text-ellipsis text-left w-full">{event.title}</div>
                  <div className="text-blue-500 text-sm">{startToEndDateString(new Date(event.start_time), new Date(event.end_time))}</div>
                </button>
              </Link>
            ))
          }
        </div>
      }
    </div>
  );
}

export default EventList;
