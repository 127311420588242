import { useMemo } from "react";
import { Link, Outlet } from "react-router-dom";
import EventList from "../components/EventList";

const Home = () => {

  const userName = useMemo(() => {
    return window.user.first_name + " " + window.user.last_name;
  }, []);

  return (
    <div className="grow bg-slate-900 h-auto min-h-screen flex w-full">
      <EventList />
      <div className="grow h-screen flex flex-col justify-center items-center">
        <div className="text-4xl text-white">
          Welcome, {userName ? userName : "My Friend"}
        </div>
        <div className="text-lg text-gray-500">
          Test events here, with good coverage and in less time
        </div>
        <Link to="create">
          <button className="bg-blue-900 hover:bg-blue-800 text-white mt-6 p-2 px-16 rounded-3xl">
            Create an Event
          </button>
        </Link>
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
