import { useEffect, useState } from "react";
import { Loader } from "./atoms/Loader";
import { getCurrentUser } from "./services/user.service";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Event from "./pages/Event";
import SessionActions from "./components/SessionActions";
import CreateEventForm from "./components/CreateEventForm";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  const [isFetchingToken, setIsFetchingToken] = useState(true);
  const [loginFailed, setLoginFailed] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        if (isFetchingToken) {
          await window.fetchAccessToken();
          const userData = await getCurrentUser();
          window.user = userData.data;
        }
      } catch (e) {
        setLoginFailed(true);
      } finally {
        setIsFetchingToken(false);
      }
    };
    init();
  });

  if (isFetchingToken) {
    return (
      <div className="bg-slate-900 h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (loginFailed) {
    return (
      <div className="bg-slate-900 h-screen flex flex-col justify-center items-center">
        <div className="text-blue-500 text-4xl mb-8" role="status">
          You are not logged in
        </div>
        <div className="text-white">
          Log into
          <a
            href={window.configs?.APP_EVENTS}
            className="text-blue-500 underline"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Event App{" "}
          </a>
          or
          <a
            href={window.configs?.APP_STUDIO}
            className="text-blue-500 underline"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Studio App{" "}
          </a>
          and come back!
        </div>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <div className="bg-slate-900 h-auto min-h-screen flex justify-center items-center">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />}>
              <Route path="create" element={<CreateEventForm />} />
            </Route>
            <Route path="e/:eventId" element={<Event />}>
              <Route path=":broadcastId" element={<SessionActions />}></Route>
            </Route>
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
