/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:88095e57-0081-47a9-9b53-5fc41198e69f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2PcfEbJ4T",
    "aws_user_pools_web_client_id": "2io2f0e76p6qpn29imp7kkfl6f",
    "oauth": {},
    "aws_user_files_s3_bucket": "goldcastb65975d1708740ac9c34b541c68b041c101640-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};
// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:d508b56d-abc9-4166-a224-5cc11e81cf41",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_YAo6iviMt",
//     "aws_user_pools_web_client_id": "3h77o4l5v0eq6no3n8cpp7gade",
//     "oauth": {},
//     "aws_user_files_s3_bucket": "goldcastb65975d1708740ac9c34b541c68b041c185242-prod",
//     "aws_user_files_s3_bucket_region": "us-east-1"
// };


export default awsmobile;
