import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { getEnv } from './constants';

const awsconfigoverrides = getEnv("APP_AMPLIFY_CONFIG_OVERRIDES");

if (awsconfigoverrides) {
  awsconfig.aws_cognito_identity_pool_id =
    awsconfigoverrides.AWS_COGNITO_IDENTITY_POOL_ID;
  awsconfig.aws_cognito_region = awsconfigoverrides.AWS_COGNITO_REGION;
  awsconfig.aws_project_region = awsconfigoverrides.AWS_PROJECT_REGION;
  awsconfig.aws_user_files_s3_bucket =
    awsconfigoverrides.AWS_USER_FILES_S3_BUCKET;
  awsconfig.aws_user_files_s3_bucket_region =
    awsconfigoverrides.AWS_USER_FILES_S3_BUCKET_REGION;
  awsconfig.aws_user_pools_id = awsconfigoverrides.AWS_USER_POOLS_ID;
  awsconfig.aws_user_pools_web_client_id =
    awsconfigoverrides.AWS_USER_POOLS_WEB_CLIENT_ID;
}

Amplify.configure({
  ...awsconfig
  // "aws_user_files_s3_bucket": "goldcast-assets123211-dev",
  // "aws_user_files_s3_bucket_region": "us-east-1"
});

Auth.configure({
  // Note - Comment out the cookieStorage object if login fails on iPhone Simulator
  cookieStorage: {
    domain: getEnv("APP_AUTH_COOKIE_DOMAIN"),
    secure: true,
    path: "/"
  }
});

window.fetchAccessToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  window.accessToken = user.signInUserSession.accessToken.jwtToken;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
