export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const timeFormatter = new Intl.DateTimeFormat("en", {
  hour: "numeric",
  minute: "numeric"
});

export const dateFormatter = new Intl.DateTimeFormat("en", {
  month: "short",
  day: "numeric"
});

export const startToEndDateString = (_startDate, _endDate) => {
  try {
    const startDate = new Date(_startDate);
    const endDate = new Date(_endDate);
    const startTimeString = timeFormatter.format(startDate);
    const endTimeString = timeFormatter.format(endDate);
    /* eslint-disable */
    return datesAreOnSameDay(startDate, endDate)
      ? `${dateFormatter.format(startDate)}, ${startTimeString} - ${endTimeString}`
      : dateFormatter.formatRange(startDate, endDate);
    /* eslint-enable */
  } catch (e) {
    return "";
  }
};