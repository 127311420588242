import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../atoms/Loader";
import {
  setUpLoadTest,
  getSetupStatus,
  cleanUpLoadTest,
} from "../services/automationTest.service";
import { useSelector, useDispatch } from "react-redux";

const SetupLoadTest = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { eventId: event_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  const create = async () => {
    navigate(`/e/${event_id}`);
    try {
      setLoading(true);
      await setUpLoadTest(event_id);
      setTimeout(() => {
        setLoading(false);
        getStatus();
      }, 1000);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const getStatus = async () => {
    setCreating(true);
    const data = await getSetupStatus();
    if (data?.code === "ok") {
      setCreating(false);
      dispatch({
        type: "UPDATE_SETUP_STATUS",
        payload: {
          isSetupReady: true,
          eventId: data?.eventId,
        },
      });
      return;
    }
    setTimeout(() => {
      getStatus();
    }, 5000);
  };

  const cleanUp = async () => {
    try {
      await cleanUpLoadTest();
      dispatch({
        type: "CLEAR_SETUP_STATE",
      });
      navigate("/");
    } catch (error) {
      alert("Error cleaning up");
    }
  };

  if (state.isSetupReady && state.eventId === event_id) {
    return (
      <div className="flex justify-center items-center gap-4">
        <div className="bg-green-500 truncate text-white mt-6 p-2 px-16 rounded-3xl">
          Setup Done!!!
        </div>
        <button
          onClick={cleanUp}
          className="bg-blue-900 cursor-pointer truncate hover:bg-blue-800 text-white mt-6 p-2 px-16 rounded-3xl"
        >
          Clear Setup
        </button>
      </div>
    );
  }

  if (loading || creating) {
    return (
      <div className="flex z-50 justify-center w-full absolute">
        <div className="bg-slate-800 border-2 border-solid border-slate-700 max-w-2xl w-fit h-96 rounded-md p-16 flex justify-center items-center text-white flex-col gap-8 text-center">
          <div>
            {loading && (
              <div className="text-green-500 text-2xl mb-4">
                Sit back and Relax!!! We are setting up the automation test for
                you. :)
              </div>
            )}
            {creating && (
              <div className="text-green-500 text-2xl mb-4">
                Now let backend breathe. It will take some time to create the
                setup
              </div>
            )}
          </div>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <button
      onClick={create}
      className="bg-blue-900 cursor-pointer hover:bg-blue-800 text-white mt-6 p-2 px-16 rounded-3xl"
    >
      Setup the Automation Test
    </button>
  );
};

export default SetupLoadTest;
