if (window.location.host === "tester.goldcast.io") {
  window.configs = {
    APP_AMPLIFY_CONFIG_OVERRIDES: {
      AWS_COGNITO_IDENTITY_POOL_ID:
        "us-east-1:d508b56d-abc9-4166-a224-5cc11e81cf41",
      AWS_COGNITO_REGION: "us-east-1",
      AWS_PROJECT_REGION: "us-east-1",
      AWS_USER_FILES_S3_BUCKET:
        "goldcastb65975d1708740ac9c34b541c68b041c185242-prod",
      AWS_USER_FILES_S3_BUCKET_REGION: "us-east-1",
      AWS_USER_POOLS_ID: "us-east-1_YAo6iviMt",
      AWS_USER_POOLS_WEB_CLIENT_ID: "3h77o4l5v0eq6no3n8cpp7gade",
    },
    APP_AUTH_COOKIE_DOMAIN: "goldcast.io",
    APP_BACKEND_SERVER: "https://backend.goldcast.io/",
    APP_EVENTS: "https://events.goldcast.io",
    APP_STUDIO: "https://admin.goldcast.io",
  };
} else {
  window.configs = {
    APP_AMPLIFY_CONFIG_OVERRIDES: {
      AWS_COGNITO_IDENTITY_POOL_ID:
        "us-east-1:ad50d85f-5f97-4c4f-9cab-6eb3da0dad6c",
      AWS_COGNITO_REGION: "us-east-1",
      AWS_PROJECT_REGION: "us-east-1",
      AWS_USER_FILES_S3_BUCKET:
        "goldcastb65975d1708740ac9c34b541c68b041c124507-alpha",
      AWS_USER_FILES_S3_BUCKET_REGION: "us-east-1",
      AWS_USER_POOLS_ID: "us-east-1_OkGwc93RD",
      AWS_USER_POOLS_WEB_CLIENT_ID: "563bbka303k862o4q8t0qg360l",
    },
    APP_AUTH_COOKIE_DOMAIN: "goldcast.io",
    APP_BACKEND_SERVER: "https://backend.alpha.goldcast.io/",
    APP_EVENTS: "https://events.dev.goldcast.io",
    APP_STUDIO: "https://admin.dev.goldcast.io",
    LOAD_TESTER_URL: "https://automationsuite.goldcast.io",
    REACT_APP_MODE: "alpha",
  };
}

export function getEnv(name) {
  return window?.configs?.[name] || process.env[name];
}
