import backend from "./apiClient/backend.ts";

export function getUserEvents(date) {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);

  const end = new Date(date);
  end.setHours(23, 59, 59, 999);

  return backend.get(`/event`, {
    organization: window.user.organization,
    limit: 20,
    start_time_gte: start.toISOString(),
    end_time_lte: end.toISOString(),
    order_by: "start_time"
  });
}

export function getEventUser(user) {
  return backend.get(`event/event-members/${user}/`);
}

export async function getEventFromUberEndpoint(id) {
  const response = await backend.get(`event/uber/`, { event_id: id });
  return response.data;
}

export async function registerUser(email, eventId, firstName, lastName) {
  const response = await backend.post("core/user/internal_registration/", {
    email,
    event_id: eventId,
    first_name: firstName,
    last_name: lastName
  });
  return response.data;
}

export async function addEventSpeaker(email, eventId, firstName, lastName) {
  const body = {
    user: {
      company:"",
      description:"",
      email:email,
      first_name:firstName,
      last_name:lastName,
      linkedin_url:"",
      profile_picture_url:"",
      title:"",
      twitter_url:""
    },
    role:4,
    event:eventId,
    file:null
  };
  
  const response = await backend.post("event/event-roles/", body);
  return response.data;
}