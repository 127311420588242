export const getDarkColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
  }
  return color;
}

export const getPercent = (num, den) => {
  return Math.round((num / den) * 10000) / 100;
}

export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value);
}