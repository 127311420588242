import React, { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../atoms/Loader";
import { startToEndDateString, timeFormatter } from "../services/time.service";
import { getPercent } from "../services/util.service";
import { useSelector } from "react-redux";

const Agenda = ({ start, duration, itemsMap, tracks }) => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const { broadcastId } = useParams();
  const minDuration = useMemo(() => {
    let minimumDuration = 60 * 60 * 1000;

    for (let id in itemsMap) {
      if (itemsMap[id].duration < minimumDuration)
        minimumDuration = itemsMap[id].duration;
    }

    return minimumDuration;
  }, [itemsMap]);

  const totalHours = useMemo(() => {
    return duration / (60 * 60 * 1000);
  }, [duration]);

  const blockWidth = useMemo(() => {
    return Math.floor((240 * (60 * 60 * 1000)) / minDuration);
  }, [minDuration]);

  const getItemSizingStyles = (hours, hoursFromEventStart) => {
    const styles = {
      top: 0,
    };
    if (hours) {
      styles.width = `${getPercent(hours, totalHours)}%`;
    }
    if (hoursFromEventStart) {
      styles.left = `${getPercent(hoursFromEventStart, totalHours)}%`;
    }
    return styles;
  };

  const AgendaItem = ({ id }) => {
    const item = itemsMap[id];
    const hours = item.duration / (60 * 60 * 1000);
    const hoursFromEventStart = (item.start_time - start) / (60 * 60 * 1000);
    const style = {
      ...getItemSizingStyles(hours, hoursFromEventStart),
      background: item.color,
    };

    return (
      <div
        className={state.isSetupReady ? "cursor-pointer" : "cursor-not-allowed"}
        onClick={() => {
          if (state.isSetupReady) {
            navigate(`${id}`);
          }
        }}
      >
        <div
          className={`h-full overflow-hidden absolute border-solid border-slate-100 border-2 opacity-50 hover:opacity-100 rounded-md p-1 text-white cursor-pointer ${
            broadcastId === id ? "border-4 opacity-100" : ""
          }`}
          style={style}
        >
          <div className="absolute right-1 bottom-1 text-sm">
            {item.broadcast_type}
          </div>
          <div className="text-xl font-bold truncate">{item.title}</div>
          <div className="text-sm">
            {startToEndDateString(item.start_time, item.end_time).split(",")[1]}
          </div>
        </div>
      </div>
    );
  };

  const AgendaTable = () => {
    const container = useRef();
    const [cursorSpec, setCursorSpec] = useState(null);

    const checkPositionAndPlaceTime = (pos) => {
      const containerPosition = container.current.getBoundingClientRect();

      const distance =
        container.current.scrollLeft + pos.clientX - containerPosition.left;

      const totalWidth = Math.max(
        totalHours * blockWidth,
        container.current.offsetWidth
      );
      const offsetTime =
        (distance * totalHours * (60 * 60 * 1000)) / totalWidth;
      const time = timeFormatter.format(new Date(start + offsetTime));
      const mountPositionFromLeft = pos.clientX - containerPosition.left;
      setCursorSpec({
        left: mountPositionFromLeft,
        time,
      });
    };

    return (
      <div className="w-full relative">
        <div
          ref={container}
          onMouseMove={checkPositionAndPlaceTime}
          className="w-full bg-slate-800 overflow-x-auto overflow-y-hidden relative"
        >
          <div
            className="h-full absolute pointer-events-none"
            style={{ minWidth: `max(${totalHours * blockWidth}px, 100%)` }}
          >
            {new Array(Math.floor(totalHours)).fill().map((x, i) => {
              return (
                <div
                  key={`col${i}`}
                  className="h-full w-1 inline-block border-solid box-border border-r-2 border-slate-400"
                  style={{ ...getItemSizingStyles(1) }}
                ></div>
              );
            })}
          </div>
          {tracks.map((track) => {
            return (
              <div
                key={track.id}
                className="h-24 box-border relative"
                style={{ minWidth: `max(${totalHours * blockWidth}px, 100%)` }}
              >
                {track.agenda_items.map((id) => (
                  <AgendaItem key={id} id={id} />
                ))}
              </div>
            );
          })}
        </div>
        {cursorSpec && (
          <div
            className="absolute w-1 h-full bg-green-500 top-0 pointer-events-none"
            style={{ left: `${cursorSpec.left}px` }}
          >
            <div className="absolute w-20 h-5 -left-10 -top-6 pointer-events-none text-white flex justify-center items-center font-bold">
              {cursorSpec.time}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!duration) {
    return <Loader />;
  }

  return (
    <div className="box-border p-16">
      <div className="text-6xl text-green-500 font-medium">Timeline</div>
      <div className="text-lg text-slate-200 mb-10">
        Use this view to find relevant sessions and play around!
      </div>
      <AgendaTable />
    </div>
  );
};

export default React.memo(Agenda);
