import { legacy_createStore as createStore, applyMiddleware } from "redux";
import stateReducer from "./reducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getEnv } from "../constants";

let middleWrapper = applyMiddleware(thunk);

if (getEnv("REACT_APP_MODE") === "alpha") {
  middleWrapper = composeWithDevTools(middleWrapper);
}

const store = createStore(stateReducer, middleWrapper);

export default store;
