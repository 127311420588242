import {  useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../atoms/Loader";
import { createFullFledgedEvent } from "../services/test.service";

const CreateEventForm = () => {
  const navigate = useNavigate();
  const sessionTypes = ["LIVE", "PRERECORDED"];
  const [sessions, setSessions] = useState(sessionTypes)
  const [startingInMinutes, setStartingInMinutes] = useState(10);
  const [durationInMinutes, setDurationInMinutes] = useState(10);
  const [creating, setCreating] = useState(false);
  const [created, setCreated] = useState(false);

  const handleSessionTypeClick = (type) => {
    const isPresent = sessions.includes(type);
    const newSessions = isPresent ? sessions.filter((s) => s !== type) : [...sessions, type];
    setSessions(newSessions);
  }

  const create = async () => {
    const start = new Date().getTime() + startingInMinutes * 60 * 1000;
    const end = start + durationInMinutes * 60 * 1000;
    setCreating(true);
    const event = await createFullFledgedEvent(start, end, sessions);
    setCreated(event.title);
    setTimeout(() => {
      navigate("/e/"+event.id);
    }, 10000)
  }

  if (creating) {
    return (
      <div className="bg-slate-800 border-2 border-solid border-slate-700 max-w-2xl w-fit h-96 absolute rounded-md p-16 flex justify-center items-center text-white flex-col gap-8 text-center">
        <div>
        {
            !created ? 
              (<div className="text-green-500 text-2xl mb-4">Take a few deep breaths</div>) : 
              (<div className="text-green-500 text-2xl mb-4">Now let backend breathe</div>)
          }  
          {
            !created ? 
              (<div>Creating the event, please be patient</div>) : 
              (<div>Redirecting to event <b className="text-green-500">{created}</b> sooon! <br/>Giving some time for Uber to have all data.<br />Refresh inside if agenda seems to not be filled.</div>)
          }  
        </div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-slate-800 border-2 border-solid border-slate-700 max-w-2xl w-full absolute rounded-md p-16">
      <Link to="/">
        <div className="absolute top-3 right-4 bg-slate-600 text-slate-400 w-8 h-8 rounded-full flex justify-center items-center cursor-pointer">X</div>
      </Link>
      <div className="text-3xl text-slate-300 font-bold">Create an event</div>
      <div className="text-md text-slate-300 font-light mb-8">Choose session types, and preferred schedule</div>
      <div className="flex w-full h-10 my-4 gap-4">
        {
          sessionTypes.map(type => {
            return (
              <div onClick={() => handleSessionTypeClick(type)} className={`rounded-md flex-1 flex justify-center items-center ${sessions.includes(type) ? "bg-blue-700" :"bg-slate-500" } hover:bg-blue-600 text-slate-100 text-md font-bold tracking-wider cursor-pointer`}>
                {type}
              </div>
            )
          })
        }
      </div>
      <div className="flex items-center text-white justify-between my-4">
        <div>Starting in <b>{startingInMinutes} minutes</b></div>
        <input onChange={e => setStartingInMinutes(e.target.value)} className="rounded-lg overflow-hidden appearance-none bg-gray-400 h-3 w-128 ml-4" type="range" min="10" max="60" step="5" value={startingInMinutes} />
      </div>
      <div className="flex items-center text-white justify-between my-4">
        <div>Duration of the event is <b>{durationInMinutes} minutes</b></div>
        <input onChange={e => setDurationInMinutes(e.target.value)} className="rounded-lg overflow-hidden appearance-none bg-gray-400 h-3 w-128 ml-4" type="range" min="10" max="60" step="5" value={durationInMinutes} />
      </div>
      <button onClick={create} className="bg-blue-700 text-white w-full rounded-md box-border border-solid border-2 border-blue-600 p-4 hover:bg-blue-600 mt-12">
        Let's Go
      </button>
    </div>
  );
}

export default CreateEventForm;
