import { getEnv } from "../../constants";
//@ts-ignore
import ApiClient from "./client.ts";

class BackendApiClient extends ApiClient {
  constructor() {
    super(getEnv("APP_BACKEND_SERVER") || "");
    this.maxAuthRetries = 5;
  }

  onUnauthorized(): Promise<void> {
    return (window as any).fetchAccessToken();
  }

  get authToken(): string {
    return (window as any).accessToken;
  }
}

export default new BackendApiClient();
