const initialState = {
  isSetupReady: false,
  eventId: "",
  uberData: {},
};
const stateReducer = (state = initialState, action) => {

  switch (action.type) {
    case "UPDATE_SETUP_STATUS":
      return {
        ...state,
        isSetupReady: true,
        eventId: action.payload.eventId,
      };
    case "CLEAR_SETUP_STATE":
      return {
        ...state,
        isSetupReady: false,
        eventId: "",
        uberData: {},
      };
    case "SET_UBER_DATA":
      return {
        ...state,
        isSetupReady: state.eventId === action.payload.event[0].id,
        eventId: action.payload.event[0].id,
        uberData: action.payload,
      };
    default:
      return state;
  }
};

export default stateReducer;
