import React, { useEffect, useState, memo } from "react";
import { Link, useOutlet, useParams } from "react-router-dom";
import { Loader } from "../atoms/Loader";
import Agenda from "../components/Agenda";
import RandomEventMagicLinkCopier from "../components/RandomEventMagicLinkCopier";
import { getEventFromUberEndpoint } from "../services/event.service";
import { startToEndDateString } from "../services/time.service";
import { userRole } from "../services/user.service";
import { getDarkColor } from "../services/util.service";
import { useDispatch } from "react-redux";
import SetupLoadTest from "../components/SetupLoadTest";

const Event = () => {
  const subRoute = useOutlet();
  const dispatch = useDispatch();

  const { eventId } = useParams();

  const [loading, setLoading] = useState(null);
  const [eventDetails, setEventDetails] = useState({});
  const [sessionMap, setSessionMap] = useState({});
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const init = async () => {
      if (loading === null) {
        setLoading(true);
        const eventData = await getEventFromUberEndpoint(eventId);
        dispatch({
          type: "SET_UBER_DATA",
          payload: eventData,
        });
        setBroadcasts(eventData);
        setLoading(false);
      }
    };

    init();
  }, [eventId, loading, dispatch]);

  const setBroadcasts = (event) => {
    const eventDetails = event.event[0];
    eventDetails.start_time = new Date(eventDetails.start_time).getTime();
    eventDetails.end_time = new Date(eventDetails.end_time).getTime();
    eventDetails.duration = eventDetails.end_time - eventDetails.start_time;

    const sessionMap = {};
    const broadcasts = event.broadcast
      .map((b) => {
        b.start_time = new Date(b.start_time).getTime();
        b.end_time = new Date(b.end_time).getTime();
        b.duration = b.end_time - b.start_time;
        b.color = getDarkColor();
        return b;
      })
      .sort(({ start_time: a }, { start_time: b }) =>
        a > b ? 1 : a < b ? -1 : 0
      )
      .map((b, i) => {
        b.order = i;
        sessionMap[b.id] = b;
        return b.id;
      });

    const tracks = event.tracks;

    tracks.forEach((t) => {
      t.agenda_items = t.agenda_items
        .split(",")
        .filter((bid) => sessionMap[bid])
        .sort((aid, bid) => {
          const a = sessionMap[aid].order;
          const b = sessionMap[bid].order;
          return a > b ? 1 : a < b ? -1 : 0;
        });
    });
    // tracks.unshift({
    //   id: "default",
    //   agenda_items: broadcasts
    // })

    console.log({
      eventDetails,
      broadcasts,
      sessionMap,
      tracks,
    });

    setEventDetails(eventDetails);
    setSessionMap(sessionMap);
    setTracks(tracks);
  };

  
  if (loading) {
    return (
      <div className="bg-slate-900 h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }
  console.log("rerenders");

  return (
    <div className="w-full h-auto flex flex-col">
      <div className="flex bg-slate-800 fixed w-full z-20 top-0 left-0  px-4 items-center text-white gap-4">
        <Link to="/">
          <div className="cursor-pointer text-slate-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
          </div>
        </Link>
        <div className=" py-4 px-8 rounded-lg">
          <div className="text-green-500 text-xl font-medium">
            {eventDetails.title}
          </div>
          <div className="text-slate-400 text-sm">
            {startToEndDateString(
              new Date(eventDetails.start_time),
              new Date(eventDetails.end_time)
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between p-8 mt-16">
        <div className="flex gap-4">
          <RandomEventMagicLinkCopier
            eventId={eventId}
            organizationId={eventDetails.organization}
            role={userRole.EVENT_MEMBER}
          />
          <RandomEventMagicLinkCopier
            eventId={eventId}
            organizationId={eventDetails.organization}
            role={userRole.BROADCAST_SPEAKER}
          />
          <RandomEventMagicLinkCopier
            eventId={eventId}
            organizationId={eventDetails.organization}
            role={userRole.ADMIN}
          />
        </div>
        <SetupLoadTest />
      </div>
      <div className="grow">
        <Agenda
          key={eventId}
          start={eventDetails.start_time}
          end={eventDetails.end_time}
          duration={eventDetails.duration}
          itemsMap={sessionMap}
          tracks={tracks}
          items={tracks[1]?.agenda_items || []}
        />
      </div>
      {subRoute && (
        <div className="w-full flex justify-center p-4">{subRoute}</div>
      )}
    </div>
  );
};

export default memo(Event);
