import { useState, memo } from "react";
import { useSelector } from "react-redux";
import { publicChatLoadTest } from "../services/automationTest.service";
import JokeLoader from "../atoms/JokeLoader";

const PublicChatLoadTest = () => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [noOfMessages, setNoOfMessages] = useState("");
  const [noOfSecs, setNoOfSecs] = useState("");
  const [error, setError] = useState(false);
  const [action, setAction] = useState("add_messages");

  const create = async () => {
    if (noOfMessages === "" || noOfSecs === "") {
      setError("Channel Id or No of Messages or No of Secs is empty!!");
      return;
    }

    if (!state.uberData.event[0].mmid) {
      setError("No Public Channel found!!");
      return;
    }

    try {
      setLoading(true);
      setError("");
      const data = await publicChatLoadTest(
        state.uberData.event[0].mmid,
        noOfMessages,
        noOfSecs,
        action
      );

      if (data?.code === 400) {
        setLoading(false);
        setError(data?.message);
        return;
      }
      setTimeout(() => {
        setLoading(false);
      }, noOfSecs * 2000);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <JokeLoader />;
  }

  return (
    <div className="bg-slate-80 top-40 max-w-2xl w-full rounded-md p-16">
      <div className="text-3xl text-slate-300 font-bold">
        Test the public chat
      </div>
      <div className="text-md text-slate-300 font-light mb-8">
        Let's test the chat
      </div>
      <div className="text-md text-red-500 font-light mb-8">{error}</div>
      {/* <div className="flex flex-col text-white justify-between my-4">
        <div>
          <label
            htmlFor="action"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Action you want to perform:
          </label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            id="action"
            value={action}
            onChange={(e)=>setAction(e.target.value)}
          >
            <option value="add_messages">Messages</option>
            <option value="add_reactions">Add Reactions</option>
          </select>
        </div>
      </div> */}
      <div className="flex flex-col text-white justify-between my-4">
        <div>
          <label
            htmlFor="token"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of messages in 1sec:
          </label>
          <input
            type="text"
            id="token"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="5"
            required
            value={noOfMessages}
            onChange={(e) => {
              setNoOfMessages(e.target.value);
              setError("");
            }}
          />
        </div>
      </div>
      <div className="flex flex-col text-white justify-between my-4">
        <div>
          <label
            htmlFor="token"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of secs:
          </label>
          <input
            type="text"
            id="token"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="2"
            required
            value={noOfSecs}
            onChange={(e) => {
              setNoOfSecs(e.target.value);
              setError("");
            }}
          />
        </div>
      </div>
      <button
        onClick={create}
        className="bg-blue-700 text-white w-full rounded-md box-border border-solid border-2 border-blue-600 p-4 hover:bg-blue-600 mt-12"
      >
        Let's Start
      </button>
    </div>
  );
};

export default memo(PublicChatLoadTest);
