import React, { useState, useEffect, useCallback } from "react";
import ChatLoadTest from "./ChatLoadTest";
import PublicChatLoadTest from "./PublicChatLoadTest";
import { useDispatch } from "react-redux";
import { getSetupStatus } from "../services/automationTest.service";
import QnALoadTest from "./QnALoadTest";
import AddSpeakers from "./AddSpeakers";

export default function SessionActions() {
  const dispatch = useDispatch();
  const [currentTabId, setCurrentTabId] = useState("chat");
  const tabs = [
    {
      name: "Chat Load Test",
      id: "chat",
    },
    {
      name: "QnA Load Test",
      id: "qna",
    },
    // {
    //   name: "Poll Load Test",
    //   id: "poll",
    // },
    {
      name: "Public Chat Load Test",
      id: "publicChat",
    },
    {
      name: "Speakers",
      id: "addSpeakers",
    }
  ];

  const componentMapper = () => {
    switch (currentTabId) {
      case "chat":
        return <ChatLoadTest />;
      case "qna":
        return <QnALoadTest />;
      case "poll":
        return <div>Poll</div>;
      case "publicChat":
        return <PublicChatLoadTest />;
      case "addSpeakers":
        return <AddSpeakers />;
      default:
        return <div>Chat</div>;
    }
  };

  const checkSetupStatus = useCallback(async () => {
    try {
      const data = await getSetupStatus();

      if (data?.code === "ok") {
        dispatch({
          type: "UPDATE_SETUP_STATUS",
          payload: {
            isSetupReady: true,
            eventId: data?.eventId,
          },
        });
      }
    } catch (error) {
      console.error("Error while checking setup status", error);
    }
  }, [dispatch]);

  useEffect(() => {
    checkSetupStatus();
  }, [checkSetupStatus]);

  return (
    <div className="max-w-5xl w-full z-50 border-2 border-solid border-slate-700 rounded-lg">
      <div className="flex flex-col h-auto rounded-lg bg-slate-800">
        <div className="flex justify-center bg-slate-900 items-center rounded-lg py-2">
          {tabs.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                setCurrentTabId(item.id);
              }}
              className="text-center truncate mx-2 hover:scale-110 cursor-pointer w-48 border-solid rounded-xl border-2 border-slate-700 p-4 bg-blue-800 text-white"
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="flex justify-center p-4">{componentMapper()}</div>
      </div>
    </div>
  );
}
