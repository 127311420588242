import React, { useEffect, useState } from "react";
import { Loader } from "../atoms/Loader";
import {
  getEventAttendees,
  getEventSpeakers,
  getEventUserMagicLink,
  getOrganizationMembers,
  userRole,
} from "../services/user.service";
import { copyToClipboard } from "../services/util.service";

const RandomEventMagicLinkCopier = ({ eventId, organizationId, role }) => {
  const [eventUsers, setEventUsers] = useState([]);
  const [typeOfUser, setTypeOfUser] = useState("member");
  const [copying, setCopying] = useState(false);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    const initialize = async () => {
      if (eventId && organizationId && role !== undefined) {
        if (role === userRole.BROADCAST_SPEAKER) {
          setEventUsers(await getEventSpeakers(eventId));
          setTypeOfUser("speaker");
        } else if (role === userRole.ADMIN) {
          setEventUsers(await getOrganizationMembers(organizationId));
          setTypeOfUser("organizer");
        } else {
          setEventUsers(await getEventAttendees(eventId));
          setTypeOfUser("attendee");
        }
      }
    };
    initialize();
  }, [eventId, organizationId, role]);
  const copyMagicLink = async () => {
    if (copying) {
      return;
    }
    setCopying(true);
    console.log(eventUsers);
    const randomUser =
      eventUsers[Math.floor(Math.random() * eventUsers.length)];
    const magicLink = await getEventUserMagicLink(
      randomUser.email || randomUser.user.email,
      eventId
    );
    copyToClipboard(magicLink);
    setCopying(false);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };
  return (
    <button
      onClick={copyMagicLink}
      className={`bg-slate-800 truncate h-20 w-46 rounded-lg border-solid border-2 border-slate-700 p-8 flex flex-col justify-center items-center text-white transition-all ${
        copied
          ? "bg-green-700 border-green-800"
          : "hover:bg-blue-800 hover:border-blue-700"
      }`}
    >
      {copying ? (
        <Loader />
      ) : (
        <>
          <b className="text-lg capitalize">{`${typeOfUser} Link`}</b>
          <div className="text-sm">{`Click to copy a random one`}</div>
        </>
      )}
    </button>
  );
};

export default React.memo(RandomEventMagicLinkCopier);
